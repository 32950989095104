/*  make the footer stick to the bottom of the page */
/*
:root {
     — space: 1.5em 0;
     — space: 2em 0;
}
*/
.Site {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.link:hover {
    cursor: pointer !important;
}

.sticky {
    
}

h1 {
    color: #027ab7;
    font-weight: 700;
    margin: 1em 0;
}

h5 {
    text-transform: uppercase;
    color:#027ab7;
    font-weight: 600;
    margin-top: 1em;
    margin-bottom: 1em;
}

p {
    color: #444;
}

.image {
    width: 98%;
}

.capitalize {
    text-transform: capitalize;
}

.btn-primary {
    background: #027ab7;
    border-color: #027ab7;
}

.btn-primary:focus, .btn-primary:active, .btn-primary:hover {
    outline: none !important;
    box-shadow: none;
    border-color: #027ab7;
}

.btn-primary:hover {
    background: #047cb9;
}

.brand-color {
    color: #fbce03
}

.brand-color-bg {
    background: #027ab7
}

.thumb {
    width: 120px;
}

@media (min-width: 768px) {
    .border-md-left {
      border-left: 1px solid #e3e7eb !important;
    }

    ._sticky {
        left: 50%;
        position: fixed;
        top:0;
        height: 100%;
        padding-top: 24px;
        z-index: 0;
    }
}

@media (min-width: 992px) {
    ._sticky {
        left: 66%;
        position: fixed;
        top:0;
        height: 100%;
        padding-top: 24px;
        z-index: 0;
    }
}

@media (min-width: 1200px) {
    ._sticky {
        left: 75%;
        position: fixed;
        top:0;
        height: 100%;
        padding-top: 24px;
        z-index: 0;
    }
}

.Site-content {
    flex: 1 0 auto;
    padding: var( — space) var( — space) 0;
    width: 100%;
}
.Site-content:after {
    content: ‘\00a0’;
    display: block;
    margin-top: var( — space);
    height: 0;
    visibility: hidden;
}